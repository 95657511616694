<template>
<div>
  <streaming-editor class="m-2"
    canvas-id="canvas"
    :config="{hlsBucket:'test-streaming'}"
    />
  <canvas id="canvas" width="854" height="480" style="background-color: black"></canvas>
</div>
</template>

<script>
import { getLog } from "@/services/log";
import streamingEditor from "@/components/streamRecorder.vue";
let log = getLog("test-streaming");

export default {
  components: { 
    streamingEditor 
  },
  data() {
    return {
      sun: new Image(),
      moon: new Image(),
      earth: new Image(),
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      log.log("init");
      this.sun.src = require("@/assets/Canvas_sun.png");
      this.moon.src = require("@/assets/Canvas_moon.png");
      this.earth.src = require("@/assets/Canvas_earth.png");
      window.requestAnimationFrame(this.draw.bind(this));
    },
    draw() {
      var ctx = document.getElementById('canvas').getContext('2d');
      let c = {x: 854/2, y: 480/2};

      ctx.globalCompositeOperation = 'destination-over';
      ctx.clearRect(0, 0, 854, 480); // clear canvas

      ctx.fillStyle = 'rgba(0, 0, 0, 0.4)';
      ctx.strokeStyle = 'rgba(0, 153, 255, 0.4)';
      ctx.save();
      ctx.translate(c.x, c.y);

      // Earth
      var time = new Date();
      ctx.rotate(((2 * Math.PI) / 60) * time.getSeconds() + ((2 * Math.PI) / 60000) * time.getMilliseconds());
      ctx.translate(105, 0);
      ctx.fillRect(0, -12, 40, 24); // Shadow
      ctx.drawImage(this.earth, -12, -12);

      // Moon
      ctx.save();
      ctx.rotate(((2 * Math.PI) / 6) * time.getSeconds() + ((2 * Math.PI) / 6000) * time.getMilliseconds());
      ctx.translate(0, 28.5);
      ctx.drawImage(this.moon, -3.5, -3.5);
      ctx.restore();

      ctx.restore();

      ctx.beginPath();
      ctx.arc(c.x, c.y, 105, 0, Math.PI * 2, false); // Earth orbit
      ctx.stroke();

      ctx.drawImage(this.sun, c.x - 150, c.y - 150, 300, 300);

      window.requestAnimationFrame(this.draw.bind(this));
    },
  }
}
</script>

<style>

</style>